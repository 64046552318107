<template>
  <div :id="anchorName" class="card-style3">
    <div v-if="name" :class="{ 'article-sec-title': hasBgTitle }">
      <h3 class="dynamic-main-title tmw-40-32-15">{{ name }}</h3>
    </div>
    <div class="card-style3-owl" :class="owlClass">
      <v-container class="inner-container inner-container--content">
        <component
          v-if="showTopWave"
          :is="topWaveComponent"
          class="top-wave"
        ></component>
        <Owl
          v-if="hasItems"
          :options="computedOptions"
          :refreshKey="refreshKey"
          @resize="resize"
        >
          <template v-slot:items>
            <div v-for="(item, i) in items" :key="i">
              <ArticleItem :item="item" />
            </div>
          </template>
        </Owl>
        <component
          v-if="showBottomWave"
          :is="bottomWaveComponent"
          class="bottom-wave"
          :class="bottomWaveClass"
        ></component>
      </v-container>
    </div>
  </div>
</template>

<script>
import EcvWaveT from "@/components/wave/ecvWaveT.vue";
import EcvWaveArticle from "@/components/wave/ecvWaveArticle.vue";
import ArticleItem from "./item/articleItem.vue";
import owl from "../owl.js";
import cardListMixins from "@/components/dynamic/card/list/list.js";
export default {
  mixins: [owl, cardListMixins],
  props: {
    location: {
      type: String,
      default: "",
    },
    index: {
      type: Number,
    },
  },
  components: {
    ArticleItem,
  },
  data: () => ({
    defaultOptions: {
      rewind: false,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
        },
        576: {
          items: 2,
          nav: false,
          dots: true,
        },
        769: {
          items: 2,
          nav: false,
          dots: true,
        },
        900: {
          items: 3,
          nav: true,
          dots: false,
        },
      },
      nav: true,
      dots: false,
      margin: 60,
    },
    refreshKey: 0,
  }),
  computed: {
    windowWidth() {
      return this.$store.getters["base/windowWidth"];
    },
    hasNav() {
      if (this.windowWidth > 900) {
        if (this.items.length > 3) return true;
      }
      return false;
    },
    computedOptions() {
      let computedOptions = {
        ...this.defaultOptions,
        ...this.options,
      };

      const length = this.items.length;

      if (length <= 3) {
        const mergeResponsive = _merge(
          {
            responsive: computedOptions.responsive,
          },
          {
            responsive: {
              0: {
                mouseDrag: length > 1,
                touchDrag: length > 1,
                pullDrag: length > 1,
              },
              576: {
                mouseDrag: length > 2,
                touchDrag: length > 2,
                pullDrag: length > 2,
              },
              769: {
                mouseDrag: length > 3,
                touchDrag: length > 3,
                pullDrag: length > 3,
              },
              900: {
                mouseDrag: length > 3,
                touchDrag: length > 3,
                pullDrag: length > 3,
              },
            },
          }
        ).responsive;

        return {
          ...computedOptions,
          responsive: mergeResponsive,
        };
      }

      return computedOptions;
    },
    topWaveComponent() {
      return EcvWaveArticle;
    },
    hasBgTitle() {
      if (!this.location) return false;
      if (this.location == "first") return false;
      return true;
    },
    showTopWave() {
      if(this.index == 0) {
        return !!this.name
      }
      if (!this.location) return true;
      if (this.location == "first") return true;
      return false;
    },
    showBottomWave() {
      if (!this.location) return true;
      if (this.location == "last") return true;
      return false;
    },
    owlClass() {
      let options = {
        "owl-has-nav": this.hasNav,
      };
      if (!this.name && ["middle", "last"].includes(this.location)) {
        options["pt-0"] = true;
        if(this.location == 'last') options["pb-15"] = true;
        else options["pb-8"] = true;
      } else {
        options["py-8"] = true;
      }
      return options;
    },
  },
  methods: {
    async resize() {
      if (this.items > 3) return;

      this.refreshKey += 1;
      await this.$nextTick();
      this.setNavIcon();
    },
  },
};
</script>